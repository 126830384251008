/* eslint-disable react/jsx-key */
import React, { useEffect, useMemo, useRef, forwardRef, useImperativeHandle } from 'react';
import type { Ref, RefAttributes, ReactElement, ReactNode } from 'react';
import { useDispatch } from 'react-redux';

import classNames from 'classnames';

import {
  useTable,
  usePagination,
  useRowSelect,
  useFilters,
  useSortBy,
  useGlobalFilter,
} from 'react-table';
import type { Column } from 'react-table';

import { ConfirmationModal, Pagination, DeleteLable, EmptyStatus } from 'Components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';

import type { RowDisabled } from '@eltoro-ui/components/Table';

import unsortedIcon from 'assets/Images/unsorted.png';

import { setGlobalFilters, globalFilters } from 'Redux/actions';
import { isPrimitive } from 'Utils/helpers';

import type { AnyObject } from 'types';

import './ReactTable.scss';
import { usePermissionContext } from 'contexts/PermissionContext';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';
import { useLocation } from 'react-router-dom';

function fixedForwardRef<T, P = {}>(
  render: (props: P, ref: Ref<T>) => ReactElement | null
): (props: P & RefAttributes<T>) => ReactElement | null {
  return forwardRef(render) as any;
}

// Check-box
const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }: any, ref) => {
  const defaultRef = useRef();
  const resolvedRef: any = ref || defaultRef;

  useEffect(() => {
    if (!!resolvedRef && !!resolvedRef.current && !!resolvedRef.current.indeterminate) {
      resolvedRef.current.indeterminate = indeterminate;
    }
  }, [resolvedRef, indeterminate]);
  return (
    <div className="table_checkbox">
      <input
        ref={resolvedRef}
        {...rest}
        id="wp-comment-cookies-consent"
        name="wp-comment-cookies-consent"
        type="checkbox"
        value="yes"
      />
    </div>
  );
});

type DeleteModalHeaderFunc = (selectedRowsLength: number) => string;

type TableTypes = {
  loading?: boolean;
  emptyText?: ReactNode | string;
  className?: string;
  onDelete?: (params: any[]) => void;
  deleteModal?: boolean;
  setDeleteModal?: (params: boolean) => void;
  deleteIsLoading?: boolean;
  selectFeature?: boolean;
  selectAllFeature?: boolean;
  title?: string;
  deletable?: boolean;
  currentPage?: number;
  pageSize?: number;
  pageCount?: number;
  totalCount?: number;
  setPage?: (page: number) => void;
  deleteModalNote?: string;
  deleteModalHeader?: string | DeleteModalHeaderFunc;
  isSortable?: boolean;
};
export type PanelRender<RecordType> = (data: readonly RecordType[]) => ReactNode;

export interface TableProps<RecordType extends object = {}> extends TableTypes {
  data: RecordType[];
  columns: Column<RecordType>[];
  header?: PanelRender<RecordType>;
  rowDisabled?: RowDisabled<RecordType>;
}

const ReactTable = fixedForwardRef(
  <RecordType extends AnyObject = AnyObject>(
    {
      className,
      columns,
      data,
      rowDisabled,
      loading,
      emptyText,
      onDelete = () => {},
      deleteModal,
      setDeleteModal = () => {},
      deleteIsLoading,
      selectFeature = true,
      selectAllFeature = true,
      title = 'item',
      header,
      deletable = true,
      pageSize,
      currentPage = 1,
      totalCount = data.length ?? 0,
      pageCount = 1,
      setPage = () => undefined,
      deleteModalNote,
      deleteModalHeader,
      isSortable = true,
    }: TableProps<RecordType>,
    ref: Ref<unknown> | undefined
  ) => {
    const location = useLocation();
    const { userIsActive } = usePermissionContext();
    const orderlineViewFlag = useFlag('orderline-view');
    const { flagsReady } = useFlagsStatus();
    const getCheckBox = (hooks: any) => {
      hooks?.visibleColumns?.push((_columns: any) =>
        selectFeature
          ? [
              {
                id: 'selection',
                Header: ({ getToggleAllPageRowsSelectedProps, toggleRowSelected }: any) => {
                  const onChange = (event: any) => {
                    page.forEach((row: any) => {
                      if (!rowDisabled || (rowDisabled && !rowDisabled(row.original)))
                        toggleRowSelected(row.id, event.currentTarget.checked);
                    });
                  };
                  const IndeterminateCheckboxProps = {
                    ...getToggleAllPageRowsSelectedProps(),
                    disabled: !userIsActive,
                    style: {
                      cursor: !userIsActive ? 'not-allowed' : 'pointer',
                    },
                    onChange,
                  };
                  return (
                    <>
                      {selectAllFeature && (
                        <IndeterminateCheckbox {...IndeterminateCheckboxProps} />
                      )}
                    </>
                  );
                },
                Cell: ({ row }: any) => (
                  <div>
                    <IndeterminateCheckbox
                      {...row?.getToggleRowSelectedProps({
                        disabled: (rowDisabled && rowDisabled(row.original)) || !userIsActive,
                        style: {
                          cursor:
                            (rowDisabled && rowDisabled(row.original)) || !userIsActive
                              ? 'not-allowed'
                              : 'pointer',
                        },
                      })}
                    />
                  </div>
                ),
              },
              ..._columns,
            ]
          : [..._columns]
      );
    };

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      pageCount: tablePageCount,
      selectedFlatRows,
      setGlobalFilter,
      toggleAllRowsSelected,
      state: { pageIndex, pageSize: tablePageSize, globalFilter },
      columns: reactTableColumns,
    }: any = useTable(
      {
        columns,
        data,
        // @ts-ignore
        manualPagination: true,
        pageCount,
        useControlledState: state => {
          return useMemo(
            () => ({
              ...state,
              pageIndex: currentPage - 1,
              pageSize: pageSize ?? 20,
            }),
            [state, pageSize]
          );
        },
      },
      getCheckBox,
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination,
      useRowSelect
    );
    const dispatch = useDispatch();

    useImperativeHandle(
      ref,
      () => ({
        resetSelectedRows: () => toggleAllRowsSelected(false),
      }), // you can just use ({toggleAllRowsSelected}) and in Parent call tableRef.current.toggleAllRowsSelected() with ?bool param
      []
    );

    useEffect(() => {
      dispatch(setGlobalFilters(setGlobalFilter));
      dispatch(globalFilters(globalFilter));
    }, []);

    const getSortStatus = (isSortedDesc: boolean) => {
      return isSortedDesc ? (
        <FontAwesomeIcon
          style={{ fontSize: '1.2rem', color: '#4496FC' }}
          color="#AAB2B5"
          icon={faAngleDown}
        />
      ) : (
        <FontAwesomeIcon
          style={{ fontSize: '1.2rem', color: '#4496FC' }}
          color="#AAB2B5"
          icon={faAngleUp}
        />
      );
    };

    const deleteHeading = useMemo(() => {
      if (deleteModalHeader) {
        if (typeof deleteModalHeader === 'function')
          return deleteModalHeader(selectedFlatRows.length);
        return deleteModalHeader;
      }

      return `Are you sure you want to delete ${selectedFlatRows?.length} ${title}${
        selectedFlatRows?.length > 1 ? 's' : ''
      }?`;
    }, [deleteModalHeader, selectedFlatRows]);

    return (
      <>
        {!!selectedFlatRows?.length && (
          <DeleteLable
            deletable={deletable}
            onDelete={() => setDeleteModal(true)}
            deleteItemCounts={selectedFlatRows?.length}
            title={title}
          />
        )}
        <div className="table-loading">
          {loading && (
            <div>
              <div className="table-loading-spinning">
                <FontAwesomeIcon
                  icon={faSpinnerThird}
                  color="#FFAB03"
                  style={{ fontSize: 30, animation: 'spinner-border 0.75s linear infinite' }}
                />
              </div>
            </div>
          )}
          <div className={classNames('table-spin-container', loading && 'table-spin-blur')}>
            <div
              className={`${
                orderlineViewFlag && flagsReady && location.pathname === '/orderlines'
                  ? 'table_wrapper_orderline table_wrapper'
                  : 'table_wrapper'
              }`}
            >
              {header && <div className="table-header">{header(data)}</div>}
              <table
                cellPadding="18px"
                cellSpacing="0px"
                {...getTableProps()}
                className={classNames('mainTable', className)}
              >
                <thead className={selectFeature ? 'customTableRowSelected' : 'customTableRow'}>
                  {headerGroups.map((headerGroup: any, index: number) => {
                    if (index === 0) return null;
                    return (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column: any) => (
                          <th
                            key={column?.id}
                            className={classNames(
                              'mainTableTh',
                              column?.id === 'selection' && 'selection'
                            )}
                            title={column.header}
                          >
                            <div className={classNames('mainTableTh-sorter')}>
                              <span
                                className={classNames('mainTableTh-title')}
                                title={column.render('Header')}
                              >
                                {column.render('Header')}
                              </span>
                              {column.Header && column.canSort && isSortable && (
                                <span
                                  {...column.getHeaderProps(column?.getSortByToggleProps())}
                                  className={`sortedIcon ${
                                    selectFeature ? 'sortedIconChecked' : ''
                                  }`}
                                >
                                  {column.isSorted ? (
                                    getSortStatus(column.isSortedDesc)
                                  ) : (
                                    <img src={unsortedIcon} alt="unsortedIcon" />
                                  )}
                                </span>
                              )}
                            </div>
                          </th>
                        ))}
                      </tr>
                    );
                  })}
                </thead>
                <colgroup>
                  {selectFeature && <col className={classNames('selection-col')} />}
                  {reactTableColumns[0].columns.map((col: { id: string; Header: string }) => {
                    const className = classNames(
                      col.id === 'link' && 'link',
                      col.id === 'actions' && 'actions',
                      col.id === 'preview' && 'preview',
                      col.id === 'audience-status' && 'audience-status'
                    );
                    return <col key={col.id ?? col.Header} className={classNames(className)} />;
                  })}
                </colgroup>
                <tbody {...getTableBodyProps()} className="table-body">
                  {!page.length && (
                    <tr className="table-placeholder">
                      <td
                        className="table-cell"
                        colSpan={reactTableColumns[0].columns.length + (selectFeature ? 1 : 0)}
                      >
                        {globalFilter ? (
                          <EmptyStatus
                            heading="No results found"
                            subHeading="You might consider trying different keywords, double-checking for any spelling errors or adjusting your filters."
                          />
                        ) : (
                          emptyText ?? <EmptyStatus />
                        )}
                      </td>
                    </tr>
                  )}
                  {page.map((row: any) => {
                    prepareRow(row);
                    let rowClassName: string;
                    if (rowDisabled && rowDisabled(row.original)) {
                      rowClassName = 'mainTableTr mainTableDisabled';
                    } else {
                      rowClassName = row.isSelected ? 'mainTableTrSelected' : 'mainTableTr';
                    }

                    return (
                      <tr {...row.getRowProps()} className={rowClassName}>
                        {row.cells.map((cell: any) => {
                          return (
                            <td
                              className={classNames(
                                selectFeature ? `mainTableTdSelected` : `mainTableTd`,
                                cell.column?.id === 'selection' && 'selection'
                              )}
                              {...cell.getCellProps()}
                              title={isPrimitive(cell.value) ? cell.value : null}
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {/* table pagination */}
            {pageCount > 1 && (
              <Pagination
                loading={false}
                rangeOfItems={tablePageSize}
                currentPage={pageIndex + 1}
                totalItems={totalCount}
                totalPages={tablePageCount}
                onClickPrev={() => setPage(pageIndex)}
                onClickNext={() => setPage(pageIndex + 1 + 1)}
              />
            )}
            {/* delete item/items confirmation modal  */}
            {deleteModal && (
              <ConfirmationModal
                heading={deleteHeading}
                note={deleteModalNote}
                onSubmit={() => onDelete(selectedFlatRows)}
                onCancel={() => setDeleteModal(false)}
                loading={deleteIsLoading}
                okText="Yes, Delete"
                cancelText="No, Thanks"
                deletion
              />
            )}
          </div>
        </div>
      </>
    );
  }
);

export { ReactTable };
