import React from 'react';
import { useHistory } from 'react-router-dom';
import emptyGoal from 'assets/Images/emptyGoal.png';
import { Button, FlexBox } from '@eltoro-ui/components';
import { GoalSectionTitles } from 'enums';

type AudienceEmptyStatesProps = {
  goal: GoalSectionTitles;
};
export default function AudienceEmptyStates({ goal }: AudienceEmptyStatesProps) {
  const history = useHistory();
  const buttonContent = {
    [GoalSectionTitles.PROMOTE_LISTING]: (
      <Button
        disabled={false}
        size="m"
        kind="default"
        weight="bold"
        onClick={() => history.replace('/prospects/sell-your-listing-faster')}
        UNSAFE_className="campaign_action_btn"
      >
        Sell Your Listing Faster
      </Button>
    ),
    [GoalSectionTitles.RETENTION]: (
      <Button
        disabled={false}
        size="m"
        kind="default"
        weight="bold"
        onClick={() => history.replace('/prospects/talk-to-your-sphere')}
        UNSAFE_className="campaign_action_btn"
      >
        Talk to Your Sphere
      </Button>
    ),
    [GoalSectionTitles.CONQUEST_LEADS]: (
      <FlexBox gap="13px" alignItems="center">
        <Button
          disabled={false}
          size="m"
          kind="default"
          weight="bold"
          onClick={() => history.replace('/prospects/grow-your-sphere')}
          UNSAFE_className="campaign_action_btn"
        >
          Grow Your Sphere
        </Button>
        <span className="budge">&bull;</span>
        <Button
          disabled={false}
          size="m"
          kind="default"
          weight="bold"
          onClick={() => history.replace('/prospects/likely-sellers')}
          UNSAFE_className="campaign_action_btn"
        >
          Likely Sellers
        </Button>
        <span className="budge">&bull;</span>
        <Button
          disabled={false}
          size="m"
          kind="default"
          weight="bold"
          onClick={() => history.replace('/prospects/digital-farming')}
          UNSAFE_className="campaign_action_btn"
        >
          Digital Farming
        </Button>
      </FlexBox>
    ),
    [GoalSectionTitles.BRANDING_AWARENESS]: (
      <Button
        disabled={false}
        size="m"
        kind="default"
        weight="bold"
        onClick={() => history.replace('/prospects/virtual-just-listed-just-sold')}
        UNSAFE_className="campaign_action_btn"
      >
        Virtual Just Listed Just Sold
      </Button>
    ),
  };
  return (
    <FlexBox
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      UNSAFE_className="empty-state-wrapper"
    >
      <img src={emptyGoal} alt="emptyGoal" />
      <p className="empty-goal title">You do not have audiences aimed at this goal.</p>
      <p className="empty-goal desc">Find prospects using either of these lenses</p>
      {buttonContent[goal]}
    </FlexBox>
  );
}
